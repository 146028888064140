import { ButtonTypeEnum } from '~/common/components/ui-elements';
export interface SearchViewModel {
  mainAd?: Ads[];
  otherAds?: Ads[];
  picksBanner?: EditorsPick[];
  searchBanner?: PopularSearch[];
  resultsData?: ResultsData[];
  selectedFilter?: string;
}

export interface Ads {
  image?: string;
  url?: string;
  buttonText?: string;
  buttonType?: keyof typeof ButtonTypeEnum;
  adTitle?: string;
  target?: '_blank' | '_self';
}

export interface EditorsPick {
  image?: string;
  url?: string;
  title?: string;
  timeRead?: string;
  datePosted?: string;
  type?: string;
}

export interface PopularSearch {
  thumbnail?: string;
  title?: string;
  url?: string;
  target?: string | 'self';
}

export interface ResultsData {
  NumberOfPages?: number;
  TotalItemCount?: number;
  ItemCount?: number;
  CurrentPage?: number;
  Hits?: SearchContent[];
  PageTypes?: { [key: string]: number };
}

export interface SearchContent {
  Heading?: string;
  Description?: string;
  Url?: string;
  ImageSrc?: string;
  ReadingTime?: string;
  PageType: string;
  PublishedDate?: Date;
}

export interface TestData {
  id: number;
  name: string;
  username: string;
  email: string;
  address: string;
}

export enum SortOptions {
  newestOldest = 'Newest-Oldest',
  mostRelevant = 'Most Relevant',
  oldestNewest = 'Oldest-Newest',
}

export enum FilterOptions {
  models = 'Models',
  owners = 'Owners',
  whySubaru = 'Why Subaru',
  buy = 'Buy',
  offers = 'Offers',
  others = 'Others',
}

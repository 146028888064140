import React, { FormEvent, useState, useEffect, useRef } from 'react';
import { Button, SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { useSearchStore } from '~/pages/search/components/search-provider';
import styles from '../index.module.scss';

interface Props {
  contentId: string;
  title?: string;
  tooltipDisclaimer?: string;
}

const SearchInput: React.FC<Props> = ({
  contentId,
  title,
  tooltipDisclaimer,
}) => {
  const { getSuggestions } = useSearchStore();
  const [searchText, setSearchText] = useState<string>('');
  const [suggestedWords, setSuggestedWords] = useState<string[] | null>(null);
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const searchListRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const LOCAL_STORAGE_KEY = 'recentSearches';

  const loadRecentSearches = (): string[] => {
    const savedSearches = localStorage.getItem(LOCAL_STORAGE_KEY);
    const parsedSearches = savedSearches ? JSON.parse(savedSearches) : [];
    return parsedSearches;
  };

  const saveRecentSearches = (newSearch: string) => {
    const savedSearches = loadRecentSearches();
    const updatedSearches = [
      newSearch,
      ...savedSearches.filter((search) => search !== newSearch),
    ].slice(0, 5);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSearches));
  };

  const handleSearch = (query: string) => {
    if (query.trim() !== '') {
      const searchUrl = `/search?query=${encodeURIComponent(query)}`;
      window.location.href = searchUrl;

      setRecentSearches((prevSearches) => {
        const newSearches = [
          query,
          ...prevSearches.filter((item) => item !== query),
        ];
        const updatedSearches = newSearches.slice(0, 5);
        saveRecentSearches(query);
        return updatedSearches;
      });
    }
  };

  const handleSubmit = (form: FormEvent<HTMLFormElement>) => {
    form.preventDefault();
    handleSearch(searchText);
  };

  const handleItemClick = (value: string) => {
    setSearchText(value);
    handleSearch(value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      searchContainerRef.current &&
      searchListRef.current &&
      !searchContainerRef.current.contains(event.target as Node) &&
      !searchListRef.current.contains(event.target as Node)
    ) {
      setSuggestedWords(null);
      setIsFocused(false);
    }
  };

  const handleClearButton = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setSearchText('');
    setSuggestedWords(null);
  };

  const handleDeleteSearch = (word: string) => {
    const savedSearches = loadRecentSearches();
    const updatedSearches = savedSearches.filter((item) => item !== word);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedSearches));
    setRecentSearches(updatedSearches);
  };

  useEffect(() => {
    const handleSuggestions = async () => {
      if (searchText.trim() !== '') {
        const results = await getSuggestions(searchText);
        setSuggestedWords(results);
      } else if (isFocused) {
        setSuggestedWords(recentSearches);
      } else {
        setSuggestedWords(null);
      }
    };

    const delayDebounceFn = setTimeout(handleSuggestions, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, isFocused]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const loadedSearches = loadRecentSearches();
    setRecentSearches(loadedSearches);
  }, []);

  return (
    <div id={contentId} className={styles.searchMacro}>
      {title && (
        <div className={styles.heading}>
          {title && <span dangerouslySetInnerHTML={{ __html: title || '' }} />}
          {tooltipDisclaimer && (
            <TooltipDisclaimer
              triggerClassName={styles.tooltip}
              disclaimer={tooltipDisclaimer}
            />
          )}
        </div>
      )}
      <div className={styles.searchForm} ref={searchContainerRef}>
        <form onSubmit={handleSubmit}>
          <input
            placeholder="What can we help you find?"
            name="search"
            type="text"
            maxLength={80}
            value={searchText}
            onFocus={() => setIsFocused(true)}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <div className={styles.clearBtn} onClick={handleClearButton}>
              <SvgIcon type="close" size={1.5} color="#1637A0" />
            </div>
          )}
          <button type="submit">
            <SvgIcon type="search" size={1.5} color="#ffffff" />
          </button>
        </form>
        {suggestedWords &&
          (recentSearches.length > 0 || suggestedWords.length > 0) && (
            <div className={styles.searchList} ref={searchListRef}>
              {suggestedWords.map((word, index) => (
                <div
                  className={`${styles.searchItem} ${
                    recentSearches.includes(word) ? styles.recentSearchItem : ''
                  }`}
                  key={index}
                  onClick={() => handleItemClick(word)}
                >
                  {word}
                  {recentSearches.includes(word) && (
                    <span
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSearch(word);
                      }}
                    >
                      &times;
                    </span>
                  )}
                </div>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default SearchInput;

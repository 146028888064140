import React, { useState, useEffect } from 'react';
import {
  ResultsData,
  SearchViewModel,
  SortOptions,
} from '../../models/search-model';
import { searchSuggestion, searchv2 } from '~/common/services/search-servicev2';

type SearchProviderContext = {
  searchTerm: string;
  contentSearchResults: ResultsData | null;
  selectedFilters: string[] | null;
  selectedPage?: string | null;
  selectedSort: SortOptions;
  isSearchLoading: boolean;
  searchData: (
    query: string,
    selectedFilter: string[] | null,
    selectedPage: string | null,
    selectedSort: SortOptions,
    newPage: boolean
  ) => Promise<ResultsData>;
  isNewPage: boolean;
  getSuggestions: (keyword: string) => Promise<string[]>;
  adsData: SearchViewModel;
};

export const SearchProvider = React.createContext<SearchProviderContext | null>(
  null
);

export const useSearchStore = (): SearchProviderContext => {
  const context = React.useContext(SearchProvider);
  if (!context) {
    throw new Error('useSearchStore must be used within a SearchProvider');
  }
  return context;
};

export const SearchStore: React.FC<SearchViewModel> = (props) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [contentSearchResults, setContentSearchResults] =
    useState<ResultsData | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[] | null>([]);
  const [selectedPage, setSelectedPage] = useState<string | null>(null);
  const [isNewPage, setIsNewPage] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<SortOptions>(
    SortOptions.mostRelevant
  );

  const getSort = (sortType: SortOptions) => {
    let sort;
    switch (sortType) {
      case SortOptions.newestOldest:
        sort = 'DefaultPublishedDateDesc';
        break;
      case SortOptions.mostRelevant:
        sort = 'Default';
        break;
      case SortOptions.oldestNewest:
        sort = 'DefaultPublishedDateAsc';
        break;
      default:
        sort = 'Default';
    }
    return sort;
  };

  const getFilter = (filter: string[] | null) => {
    const selectedFiltersWithoutCount = filter?.map((option) =>
      option.split('(')[0].trim()
    );
    const selectedFiltersString = selectedFiltersWithoutCount?.join(',');
    return selectedFiltersString;
  };

  const searchData = async (
    query: string,
    filter: string[] | null,
    page: string | null,
    sort: SortOptions,
    newPage: boolean
  ): Promise<ResultsData> => {
    try {
      setIsSearchLoading(true);
      const getSortType = getSort(sort);
      const getFilters = getFilter(filter);
      const content = await searchv2(
        query,
        getFilters || null,
        page || null,
        getSortType
      );
      setContentSearchResults(content);
      setSearchTerm(query);
      setSelectedFilters(filter);
      setSelectedPage(page);
      setSelectedSort(sort);
      setIsNewPage(newPage);
      return content;
    } catch (error) {
      throw new Error('Error fetching data');
    } finally {
      setIsSearchLoading(false);
    }
  };

  const getSuggestions = async (query: string): Promise<string[]> => {
    const removeHtmlTags = (str: string): string => {
      return str.replace(/<[^>]*>/g, '');
    };

    try {
      const suggestionContent = await searchSuggestion(query);
      let suggestions: string[] = [];

      if (suggestionContent && suggestionContent.length > 0) {
        suggestions = suggestionContent.map((content) => {
          const cleanedContent = removeHtmlTags(content);
          const trimmedContent = cleanedContent.slice(0, 60);
          return trimmedContent;
        });
      } else {
        const content = await searchv2(query, null, null, null);
        if (content && content.Hits) {
          suggestions = content.Hits.slice(0, 10)
            .map((hit) => hit.Heading)
            .filter((heading) => typeof heading === 'string')
            .map((heading) => {
              const pipeIndex = heading?.indexOf('|');
              const trimmedHeading =
                pipeIndex !== -1
                  ? heading?.slice(0, pipeIndex).trim()
                  : heading || '';
              const truncateHeading = trimmedHeading
                ? trimmedHeading.slice(0, 60)
                : '';
              return truncateHeading;
            });
        }
      }
      return suggestions;
    } catch (error) {
      throw new Error('Error fetching suggestions');
    } finally {
      setIsSearchLoading(false);
    }
  };

  return (
    <SearchProvider.Provider
      value={{
        searchTerm,
        selectedFilters,
        selectedPage,
        selectedSort,
        contentSearchResults,
        isSearchLoading,
        searchData,
        isNewPage,
        getSuggestions,
        adsData: props,
      }}
    >
      {props.children}
    </SearchProvider.Provider>
  );
};

import React from 'react';
import { SearchStore } from '~/pages/search/components/search-provider';
import SearchInput from './components/SearchInput';

interface ISearchBarContent {
  contentId: string;
  title?: string;
  tooltipDisclaimer?: string;
}

const SearchBarMacro = (props: ISearchBarContent) => {
  return (
    <div id="search-bar-content">
      <SearchStore>
        <SearchInput {...props} />
      </SearchStore>
    </div>
  );
};

export default SearchBarMacro;

import { ResultsData } from '~/pages/search/models';

const username = 'subaruteam';
const password = 'Carrot!';

export const searchSuggestion = (q: string | null): Promise<string[]> => {
  const apiUrl = `/api/search-wo/suggest?query=${q}&Index=Default_query_suggestions`;
  const basicAuth = btoa(`${username}:${password}`);
  return fetch(apiUrl, {
    headers: {
      Authorization: `Basic ${basicAuth}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      throw new Error('Error fetching data');
    });
};

export const searchv2 = (
  q: string | null,
  f: string | null,
  p: string | null,
  s: string | null
): Promise<ResultsData> => {
  let apiUrl = `/api/search-wo?query=${encodeURI(q || '')}&itemsPerPage=12`;

  if (f) {
    apiUrl += `&types=${encodeURI(f)}`;
  }

  if (p) {
    apiUrl += `&page=${encodeURI(p)}`;
  } else {
    apiUrl += `&page=1`;
  }

  if (s) {
    apiUrl += `&index=${encodeURI(s)}`;
  } else {
    apiUrl += `&index=Default`;
  }

  const basicAuth = btoa(`${username}:${password}`);
  return fetch(apiUrl, {
    headers: {
      Authorization: `Basic ${basicAuth}`,
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      throw new Error('Error fetching data');
    });
};
